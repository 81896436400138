.CourseSection{
    display: flex;
    margin-top: 4rem;
    margin-bottom: 4rem;
    

    .cs-title{
        margin-left: 2rem;
      
        h4{
            font-size: 2rem;
        }
        .cs-description{
            padding: 1rem 0;
        }
    }
    @media screen and (max-width: 1400px){
        flex-direction: column;
        .cs-title{
            margin-left: 0;
            margin-top: 1rem;
        }
    }
    
}

.course-area{
    display: flex;

    .courses {
        font-weight: bold;
    }

    p{
        padding: .3rem 0;
    }
    .course-specifications{
        .langs {
            font-weight: bold;
        }
        margin-left: 2.3rem;
    }
}


  