.InterestsPage{

}



.interests{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 2rem;
    column-gap: 1rem;
    height: 100%;
    
    @media screen and (max-width: 1600px){
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 900px){
        grid-template-columns: repeat(1, 1fr);
    }
    .portfolio{
        .image-data{
            position: relative;
            
            &::before{
                content: "";
                position: absolute;
                top: 12px;
                left: 15px;
                height: calc(100% - 30px);
                width: calc(100% - 30px);
                background-color: rgba(255, 255, 255, 0.575);
                transform-origin: center;
                transform: scale(0);
                transition: all .4s ease-in-out;
                border-radius: 20px;

            }
            &:hover::before{
                transform: scale(1);
            }
            img{
                width: 100%;
                height: 100%;
                //30vh
                object-fit: cover;
                border-radius: 20px;
            }

            .hover-items{
                list-style: none;
                position: absolute;
                //align-items: center;
                //justify-content: center;

                //TWEAKING THE CENTERING OF THE PURPLE BOX   
                top: 50%;
                left: 50%;
                left: -.5rem;
                right: 1rem;
                
                transform: translate(3%, -50%);
                padding: 1rem 2rem;
                visibility: hidden;

                //TWEAKING THE size OF THE PURPLE BOX      
                height: calc(100% - 100px);
                width: calc(100% - 60px);
                //background-color: rgba(33, 22, 199, 0.534);
                border-radius: 20px;

               
                li{
                    a{
                        
                        position: absolute;       
                        height: calc(100% - 50px);
                        width: calc(100% - 50px);
                        

                        width: 100%;
                        margin-top: 2rem;
                        padding: .5rem;
                        //FONT SIZE
                        font-size: .9rem;
                        //align-items: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-decoration: none;
                        font-family: inherit;
                        border-radius: 10px;
                        background-color: rgba(119, 5, 109, 0.849);
                        color: rgb(255, 255, 255);
                        &:not(:last-child){
                            margin-right: 2rem;
                        
                        }
                    }
                }
            }

            &:hover{
                .hover-items{
                    transition: all .5s ease-in-out .2s;
                    visibility: visible;
                    //WHERE THE PURPLE BOX ENDS UP
                    top: 40%;
                    border-radius: 20px;
                }
            }
        }
        
        h7{
            font-size: 1.7rem;
            font-weight: 400;
            color: white;
            transition: all .3s ease-in-out;
            &:hover{
                color:  rgba(25, 0, 246, 0.479);
                cursor: pointer;
            }
        }
        h8{
            font-size: 1.7rem;
            font-weight: 400;
            color: white;
            transition: all .3s ease-in-out;
           
        }
    }
}