.NavBar{
    height: 100vh;
    .nav{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        //profile picture styling
        .profile{
            width: 100%;
            text-align: center;
            margin-top: 1rem;
            border-bottom: 1px solid #2e344e;
           
            img{
                width: 70%;
                border-radius: 100%;
                border: 5px solid #2e344e;
                margin-bottom: 1rem;
            }

        }

        .nav-items{
            width: 100%;
            .nav-item{
                list-style: none;
                text-align: center;
               
                a{
                    text-decoration: none;
                    font-size: inherit;
                    color: inherit;
                    display: block;
                    padding: .5rem 0;
                    position: relative;
                    //animations
                    &::before{
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: .4rem;
                        height: 100%;
                        background-color: #a303ff4f;
                        transform-origin: bottom;
                        transform: scale(0);
                        overflow-x: hidden;
                        transition: transform .6s, .2s width .5s cubic-bezier(1,-0.16,.6,1.35);
                    }
                    
                    &:hover::before{
                        width: 100%;
                        transform: scale(1);
                    }
                }
            }
        }

        .footer{
            width: 100%;
            border-top: 1px solid #2e344e;
            p{
                text-align: center;
                padding: 1rem 0;
            }
        }
    }
}

.active{
    background-color: rgba(128, 0, 128, 0.794);
}