

.HomePage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;

}


.hero-text {
    color: white;
    text-align: center;
    font-size: 3.6rem;
    span {
        
        color: white;

    }
}

.h-sub-text {
    
    text-align: center;
    font-size: 1.15rem;
}

.icons{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 0;
    .icon-holder{
        margin-right: 1rem;
        .icon{
           font-size: 2.8rem;
           color: #a4acc4;
           transition: all .6s ease-in-out;
        }
        .li:hover{
            color: rgba(26, 26, 224, 0.767);
        }
        .gh:hover{
            color: rgba(212, 45, 212, 0.788);
        }
        .insta:hover{
            color: rgba(212, 45, 212, 0.788);
        }
        .pay:hover{
            color: rgba(13, 195, 28, 0.863);
        }
    }
}


